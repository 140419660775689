<template>
  <div class="app">
    <Layout>
      <Content class="upload_content">
        <sellerSider></sellerSider>
        <Content class="upload_content_body">
          <Layout>
            <Header>
              <div class="header-words">
                <h1 class="upload-header-words">发布视频</h1>
              </div>
              <div class="subtitle">
                                <span class="subtitle-words">{{ uploadFile.name }}</span>
              </div>
              <div class="detail-title">
                                <span class="detail-title-words">{{ uploadFile.size_show }} M</span>
                <span class="detail-title-words" @click="returnUpload" style="cursor: pointer">
                  <Icon type="md-refresh"/>
                  重新上传
                </span>
                                <span class="detail-title-words">{{ uploadFile.uplaod_time }} 上传</span>
              </div>
            </Header>
            <Content class="upload-body">
              <div class="preview">
                <div class="preview-video">
                                    <video class="video-style" height="453" width="213" autoplay loop :src="uploadFile.filePath_show"></video>
                </div>
                <div class="cover-words" style="text-align: center">
                  <span>预览视频</span>
                </div>
              </div>
              <div class="upload-forms">
                <div class="cover-words">
                  <span>预览封面</span>
                </div>
                                <img :src=" uploadFile.frame_show " alt="cover" width="100" height="150">
                <Form :model="uploadForm" class="upload-form" :rules="uploadRules" ref="uploadForm">
                  <div class="cover-words">
                    <span>视频标题</span>
                  </div>
                  <FormItem prop="title">
                    <Row>
                      <Col span="8">
                        <Input type="text" v-model="uploadForm.title"/>
                      </Col>
                    </Row>
                  </FormItem>
                  <div class="cover-words">
                    <span>视频分类</span>
                  </div>
                  <FormItem prop="category">
                    <Row v-for="(category,index) in categoryColumns" :key="index">
                      <Col span="8">
                        <span> {{ ++index }} 级分类 </span>
                        <Select
                          v-model="chosenCategories[index - 1]"
                          v-if="renderShow"
                          @on-change="displayOrChangeCategory"
                        >
                          <Option v-for="(item, index) in category" :value="item.value" :key="item.label">
                            {{item.label}}
                          </Option>
                        </Select>
                      </Col>
                    </Row>
                  </FormItem>
                  <div class="cover-words">
                    <span>上传视频说明文档</span>
                  </div>
                  <FormItem>
                    <Row>
                      <Col span="14">
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true
                                      v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError"
                        >
                          <div class="upload-content-zone">
                            <img class="upload-logo" src="../../../assets/images/upload.png"/>
                          </div>
                          <div class="upload-words">
                            <span class="click-upload-words">点击上传</span>
                            <span class="drag-upload-words">或直接将PDF文件拖拽到此区域</span>
                          </div>
                        </vue-dropzone>
                      </Col>
                    </Row>
                  </FormItem>
                  <div class="cover-words">
                    <span>视频文档阅读时长</span>
                  </div>
                  <FormItem>
                    <Row>
                      <Col span="8">
                        <Input type="text" v-model="uploadForm.pdf_duration" placeholder="输入阅读时长,单位为秒"/>
                      </Col>
                    </Row>
                  </FormItem>
                  <div class="cover-words">
                    <span>视频描述</span>
                  </div>
                  <FormItem prop="description">
                    <Row>
                      <Col span="8">
                        <Input type="textarea" v-model="uploadForm.description" :autosize="{minRows: 4,maxRows: 8}"/>
                      </Col>
                    </Row>
                  </FormItem>
                </Form>
                <div class="content-footer">
                  <Row>
                    <Col span="3">
                      <Button size="large" class="login_buttonBg"
                              @click="uploadSubmit('uploadForm')" type="primary" long>
                        发布视频
                      </Button>
                    </Col>
                    <Col span="1">&nbsp;</Col>
                    <Col span="3">
                      <Button size="large" class="cancel-btn"
                              @click="returnUpload" long>
                        取消
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
          </Layout>
        </Content>
      </Content>
    </Layout>
  </div>
</template>

<script>
  import sellerSider from "../../../components/sellerSider"
  import vue2Dropzone from 'vue2-dropzone'

  export default {
    name: "releaseUpload",
    components: {
      sellerSider,
      vueDropzone: vue2Dropzone,
    },
    data() {
      return {
        dropzoneOptions: {
          url: '/api/api/video/uploadPdf',
          thumbnailWidth: 150,
          thumbnailHeight: 150,
          maxFilesize: 300,
          showRemoveLink: true,
          maxNumberOfFiles: 1,
          acceptedFileTypes: "pdf",
          paramName: "uploadFile",
        },
        uploadFile: {
          name: "",
          size: "",
          size_show: "",
          filePath_show: "",
          filePath: "",
          frame: "",
          frame_show: "",
          ext: "",
          uplaod_time: "",
          preview_frame_path: ""
        },
        uploadForm: {
          file_name: "",
          title: "",
          url: "",
          video_format: "",
          size: "",
          description: "",
          upload_time: "",
          upload_id: "",
          upload_name: "",
          video_type: 1,
          file_path: "",
          preview_frame_url: "",
          preview_frame_path: "",
          preview_frame_name: "",
          category: "",
          preview_pdf_name: "",
          preview_pdf_path: "",
          preview_pdf_url: "",
          pdf_duration: 0,
        },
        uploadRules: {
          title: [
            {type: "string", min: 1, message: "标题不少于5位"},
            {type: "string", max: 20, message: "标题不大于20位"},
            {required: true, message: '标题不可为空'}
          ],
          description: [
            {required: true, message: '描述不可为空'}
          ]
        },
        categoryColumns: [],
        originCategory: [],
        renderShow: true,
        levels: [],
        catePids: [],
        chosenCategories:[],
      }
    },
    created() {
      this.getCategoryJson(0);
    },
    mounted() {
      this.init();
      this.getUserInfo();
      this.$nextTick(() => {
        this.rendering = true;
      });
    },
    methods: {
      init() {
        this.uploadFile = this.$route.params.uploadFile;
      },
      getUserInfo() {
        this.token = this.common.getAuth();
        let user = this.common.getStorage(this.CONSTANTS.USER_INFO);
        if (user) {
          this.userInfo = escape(this.common.userStr(user));
        } else {
          this.$Message.error("登录已过期,请重新登录");
          this.$router.push({name:"login"})
        }

        this.$refs.myVueDropzone.dropzone.options.headers = {
          "Eova-Authorization": this.token,
          user_info: this.userInfo
        };
      },
      uploadSubmit(name) {
        this.$refs[name].validate(valid => {
          if (valid) {
            let token = this.common.getAuth();
            let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
            this.uploadForm.category = this.chosenCategories[this.chosenCategories.length - 1];
            let obj = {
              id: this.uploadFile.id,
              upload_id: userInfo.id,
              title: this.uploadForm.title,
              description: this.uploadForm.description,
              category: this.uploadForm.category,
              pdf_duration: this.uploadForm.pdf_duration,
              preview_pdf_name: this.uploadForm.preview_pdf_name,
              preview_pdf_path: this.uploadForm.preview_pdf_path,
              preview_pdf_url: this.uploadForm.preview_pdf_url,
            };
            this.$axios.post(`${this.apiUrlCros}/api/api/video/updateApi`, obj, {
              "headers": {
                "Eova-Authorization": token
              }
            }).then(res => {
              if (res.data.code == "200") {
                this.$Message.success("发布成功");
                this.$router.push({name: "videoManage"});
              } else {
                this.$Message.error(res.data.message);
              }
            }).catch(err => {
              this.$Message.error(err);
            })
          }
        });
      },
      returnUpload() {
        this.$router.push({name: "upload"});
      },
      async getCategoryJson(pid) {
        let data = {};
        this.renderShow = false;
        if (this.catePids.indexOf(pid) === -1) {
          this.catePids.push(pid);
          await this.$axios.post(`${this.apiUrlCros}/api/api/videoCategory/list`, {pid: pid}).then(res => {
            if (res.data.code === "200") {
              data = res.data.resData;
              if (this.levels.indexOf(res.data.level) === -1) {
                this.levels.push(res.data.level);
              } else {
                this.removeCategory(res.data.level);
              }
              this.encapsulationData(data);
            }
          }).catch(err => {
            this.$Message.error(err);
          })
        }
        this.renderShow = true;
      },
      encapsulationData(data) {
        this.categoryColumns = [];
        if (this.originCategory.length > 0) {
          for (let i = 0; i < this.originCategory.length; i++) {
            this.categoryColumns.push(this.originCategory[i]);
          }
        }
        for (let i = 0; i < data.length; i++) {
          this.categoryColumns.push(data[i]);
        }
        if (data[0][0]["level"] === 1) {
          this.originCategory = this.categoryColumns;
        }
      },
      uploadSuccess(file, response) {
        if (response.code == "200") {
          this.uploadForm.preview_pdf_name = response.resData.filename;
          this.uploadForm.preview_pdf_path = response.resData.file_path;
          this.uploadForm.preview_pdf_url = response.resData.preview_file_url;
          this.$Message.success("上传成功");
        } else {
          this.$Message.error(response.message);
          this.reload();
        }
      },
      uploadError(file) {
        this.$Message.error("上传失败");
        this.reload();
      },
      displayOrChangeCategory(value) {
        this.getCategoryJson(value);
        if (this.chosenCategories.indexOf(value) === -1) {
          this.chosenCategories.push(value);
        }
      },
      removeCategory(level) {
        for (let i = 0; i < this.categoryColumns; i++) {
          if (level === this.categoryColumns[i][0]["level"]) {
            this.categoryColumns.remove(i);
            break;
          }
        }
      },
    }
  }
</script>

<style scoped lang="less">
  body {
    background: rgba(251, 250, 248, 1);

    .header-words {
      background: #fff;
      margin-top: -5px;

      .upload-header-words {
        font-weight: normal;
        font-size: 30px;
        line-height: 42px;
        color: #161823;
        margin-bottom: 20px;
      }
    }

    .subtitle {
      margin-bottom: 1em;
      display: flex;
      background: #fff;

      .subtitle-words {
        font-size: 16px;
        line-height: 22px;
        color: #161823;
        margin-bottom: 1em;
      }
    }

    .detail-title {
      margin-top: -32px;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      background: #fff;

      .detail-title-words {
        display: -moz-box;
        display: -ms-flexbox;
        margin-right: 24px;
        color: rgba(51, 51, 51, 0.4);
      }
    }

    .upload-body {
      margin-top: 60px;
      background: #fff;

      .preview {
        float: right;
        margin-right: 10px;

        .preview-video {
          width: 213px;
          height: 453px;

        }
      }

      .upload-forms {
        float: left;
        width: 50%;
      }

      .cover-words {
        margin-top: 32px;
        margin-bottom: 10px;

        span {
          font-size: 16px;
          line-height: 22px;
          color: #161823;
          margin: 10px 0;
        }
      }

      .upload-form {
        margin: 40px 0 10px 0;

      }
    }

    .cancel-btn {
      border-radius: 50px;
    }

    #dropzone {
      padding: 40px 45px;
      background: #FCFCFC;
      border: 2px dashed rgba(22, 24, 35, 0.06);
      border-radius: 4px;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 9rem;

      .upload-content-zone {
        position: relative;
        transform: translate(201%, -42%);
        text-align: center;
        width: 5rem;
        height: 3rem;

        .upload-logo {
          width: 100%;
          height: 100%;
        }
      }

      .upload-words {
        margin-top: 40px;
        position: absolute;
        transform: translate(19%, -186%);
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 25px;

        .click-upload-words {
          color: #0BCCF6;
        }

        .drag-upload-words {
          color: #898A8F;
        }
      }

    }
  }

  @media only screen and (min-width: 1280px) {
    body #dropzone .upload-content-zone {
      transform: translate(10%, -88%);
    }

    body #dropzone .upload-words {
      transform: translate(-16%, -126%);
      font-size: 0.3rem;
    }
  }

  @media only screen and (min-width: 1600px) {
    body #dropzone .upload-content-zone {
      transform: translate(60%, -42%);
    }

    body #dropzone .upload-words {
      transform: translate(-7%, -186%);
      font-size: 0.3rem;
    }
  }

  @media only screen and (min-width: 1920px) {
    body #dropzone .upload-content-zone {
      transform: translate(110%, -42%);
    }

    body #dropzone .upload-words {
      transform: translate(-7%, -186%);
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 2560px) {
    body #dropzone .upload-content-zone {
      transform: translate(201%, -42%);
    }

    body #dropzone .upload-words {
      transform: translate(19%, -186%);
      font-size: 1rem;
    }
  }
</style>
